<template>
	<b-card>
		<div
			v-for="column in columns"
			:key="column.key"
            class="row col-auto"
		>
            <b class="mr-1">{{ column.label }} :</b>
            <slot v-if="hasExternSlot(column.key)" :name="`custom-slot-cell(${column.key})`" v-bind:data="row"></slot>
            <span v-else-if="column.rawHtml" v-html="cleanHTML(getNestedObjectString(row.item, column.key))"></span>
			<span v-else>{{ getNestedObjectString(row.item, column.key) | prettyField(column.key) }}</span>
		</div>
	</b-card>
</template>

<script>
import Table from '@/mixins/Table'
import xss from 'xss'

export default {
	name: 'RowDetails',
	mixins: [Table],
	props: {
		row: Object,
		columns: Array,
        editSlots: Object
	},
	methods: {
        hasExternSlot(col_key) {
            const slot_key = `cell(${col_key})`
            return this.editSlots && this.editSlots[slot_key] && this.editSlots[slot_key].externSlot
        },

		cleanHTML(input) {
			return xss(input)
		}
    }
}
</script>
